import React from 'react';
import About from '../components/about/About';
import Contact from '../components/contact/Contact';
import CoreValues from '../components/core-values/CoreValues';
import IntroHeader from '../components/intro-header/IntroHeader';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ServicesSection from '../components/services/ServicesSection';

const Home = () => {
  return (
    <Layout>
      <IntroHeader />
      <About />
      <CoreValues />
      <ServicesSection />
      <Contact />
    </Layout>
  );
};

export default Home;

export const Head = () => (
  <Seo title="Home" />
);
