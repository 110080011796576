import React from 'react';

const IntroHeader = () => {
  return (
    <div className="relative p-4 overflow-hidden bg-no-repeat bg-cover" style={{ backgroundPosition: 'center center', backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, .6) 0%, rgba(0, 0, 0, .6) 100%), url("/bg2-min.jpg")' }}>
      <div className="text-center my-6 relative">
        <div className="text-6xl font-bold mb-1 text-white animate__animated animate__rotateInDownLeft">Midway Homes</div>
        <div className="text-6xl text-blue-400 font-bold mb-4 animate__animated animate__rotateInUpRight">Adult Care</div>
        <p className="mt-0 mb-3 line-height-3 text-center mx-auto text-white animate__animated animate__fadeInBottomLeft" style={{ maxWidth: '500px' }}>
          Welcome to Midway Homes Adult Care, where compassionate care meets individualized support. We provide trusted Home Adult Care Services designed to enhance the lives of adults in our community.
        </p>
      </div>
    </div>
  );
};

export default IntroHeader;
