import React from 'react';
import SingleService from './SingleService';

const ServicesSection = () => {
  return (
    <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
      <div className="font-bold text-5xl text-blue-500 mb-3">Our Services</div>
      <div className="text-700 line-height-3 text-xl mb-6">Comprehensive Home Adult Care Solutions.</div>
      <div className="flex flex-column xl:flex-row">
        <SingleService shortLabel="ICS" link="ics" label="Intergrated Community Supports" image="/blog-pic-min.jpeg" des="Integrated Community Supports (ICS) is a DHS service that provides supportive services including to adults ages 18 and older who reside in a provider-controlled ICS setting." />
        <SingleService shortLabel="RCS" link="rcs" label="Adult Residential Crisis Stabilization Services" image="/blog-pic-2-min.jpeg" des="Adult Residential Crisis Stabilization Services (RCS) are specialized programs that provide immediate and intensive support to individuals experiencing a mental health crisis or other challenging circumstances." />
      </div>
    </div>
  );
};

export default ServicesSection;
