import { navigate } from 'gatsby';
import React from 'react';

const SingleService = ({ image, shortLabel, label, des, link }) => {
  const handleClick = () => {
    navigate(`${link}`);
  };
  return (
    <div className="single-service-post flex shadow-2 surface-card border-round mr-0 xl:mr-4 mb-6 xl:mb-0 flex-column  hover:shadow-5" onClick={handleClick}>
      <img src={image} alt="blog-4" className="border-round-left" style={{ maxHeight: '234px', maxWidth: '350px' }} />
      <div className="p-4">
        <div className="flex justify-content-between mb-3">
          <span className="text-blue-500 font-medum">{shortLabel}</span>
        </div>
        <div className="font-medium text-900 mb-3 line-height-3 ">{label}</div>
        <div className="line-height-3 text-700 text-sm mb-3">{des}</div>
      </div>
    </div>
  );
};

export default SingleService;
