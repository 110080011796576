import React from 'react';

const About = () => {
  return (
    <>
      <div className="surface-section px-4 py-5 md:px-6 lg:px-8">
        <div className="border-bottom-1 surface-border">
          <span
            className="block text-blue-500 font-bold text-900 mb-4 animate__animated animate__lightSpeedInLeft animate__delay-1s"
            style={{ fontSize: '3em' }}
            id="about"
          >
            About Us
          </span>
        </div>
      </div>
      <div className="px-4 md:px-6 lg:px-8">
        <div className="mt-0 mb-5 text-700 line-height-3 text-xl">
          <span>Midway Homes Adult Care is a licensed Home and Community-Based
            Services provider dedicated to offering comprehensive and
            compassionate care to individuals in need. With our range of Basic
            Support Services and Intervention Support Services, we strive to
            enhance the quality of life, promote independence, and ensure the
            well-being of the individuals we serve.
          </span>
          <br />
          <br />
          <span>
            At Midway Homes Adult Care, we
            believe in fostering a sense of belonging and connection within the
            community. In addition to our Basic Support Services and Intervention
            Support Services, we offer integrated community support to ensure
            individuals are actively engaged and have access to a range of
            resources. We collaborate with local organizations, community centers,
            and social service agencies to provide opportunities for
            socialization, participation in community events, and access to
            recreational and educational programs. By promoting community
            integration, we strive to enhance social connections, build meaningful
            relationships, and enable individuals to lead fulfilling lives as
            valued members of their community.
          </span>
        </div>
      </div>
    </>
  );
};

export default About;
